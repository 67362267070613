<template>
  <div class="bg-white" id="LoginWrap">
    <div class="login_btn_box m-auto text-center">
      <CLink to="/home">
        <CImg src="/img/threespace/logo/space_black.png" width="70px" />
      </CLink>

      <!-- <CRow class="justify-content-center" style="padding-top: 10px">
        <CCol class="col-12 text-center text-dark">
          <p v-html="$t('login.findMessage')"></p>
        </CCol>
      </CRow> -->
      <CRow class="justify-content-center" style="padding-top: 40px">
        <div class="col-12 text-center">
          <div class="c-avatar c-avatar-xl mr-5" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/kakao_logo_100x100.png"
              @click="onLoginKakao()"
            />
          </div>
          <div class="c-avatar c-avatar-xl mr-5" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/naver_logo_180x180.png"
              @click="onLoginNaver()"
            />
          </div>
          <div class="c-avatar c-avatar-xl" style="border: 1px solid #000">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/google_logo_100x100.jpg"
              @click="onLoginGoogle()"
            />
          </div>
        </div>
      </CRow>

      <div class="or_line">
        <div class="text">OR</div>
      </div>

      <CForm @keypress.enter.prevent="onSubmitFindAccount()">
        <CRow class="justify-content-center mt-5 mb-2">
          <CCol class="col-12 text-left">
            <div
              style="
                background-color: #f7f7f7;
                padding: 6px;
                margin-bottom: 10px;
              "
            >
              <CInput
                :lazy="false"
                :value.sync="$v.form.account.$model"
                :isValid="checkIfValid('account')"
                :placeholder="$t('login.findEmail')"
                :invalidFeedback="$t('validation.requiredEmailorMobile')"
              />
            </div>
          </CCol>
        </CRow>

        <p class="text-danger mt-3" v-if="errorMessage !== null">
          {{ errorMessage }}
        </p>
        <b-button block class="space_btn" @click="onSubmitFindAccount()">
          {{ $t("login.next") }}
        </b-button>
      </CForm>

      <!-- <a
        class="mt-5 d-inline-block text text-dark cursor-pointer"
        @click="goLogin()"
      >
        <u>{{ $t("login.back") }}</u> {{ $t("login.backLink") }}
      </a> -->
    </div>

    <!-- 계정찾기/비밀번호 재설정 팝업 -->
    <b-modal
      id="resetPasswordModal"
      ref="resetPasswordModal"
      centered
      hide-footer
      hide-header
      header-border-variant="white"
      body-class="text-center pb-5 justify-content-center"
    >
      <div class="d-flex justify-content-end">
        <img
          class="cursor-pointer"
          src="/img/threespace/icon/close.png"
          alt="close"
          @click="closeJoinSuccessModal()"
        />
      </div>
      <div style="margin-bottom: 8px">
        <b class="text-black" style="font-size: 20px; font-weight: bold"
          >{{ $t("login.findAccount") }} / {{ $t("login.resetPassword") }}</b
        >
      </div>
      <!-- <template #modal-title>
        <b class="text-black"
          >{{ $t("login.findAccount") }} / {{ $t("login.resetPassword") }}</b
        >
      </template> -->

      <template v-if="!sendedEmail">
        <p>{{ $t("login.findConfirm") }}</p>

        <b-row class="justify-content-center">
          <b-col class="col-10 text-left py-3">
            <b-list-group>
              <template v-for="(item, i) in accounts">
                <b-list-group-item
                  :key="i + 1"
                  :class="{ active: selectedAccount === i + 1 }"
                  href="#"
                  @click="onSelectAccount(i + 1, item.idx)"
                  style="
                    background-color: #f7f7f7;
                    border: none;
                    border-radius: 0;
                    margin-bottom: 10px;
                  "
                  class="text-black"
                >
                  <CIcon name="cil-envelope-closed" class="mr-2" cl />
                  {{ item.email }}<br />
                  <div class="mb-2" />
                  <CIcon name="cil-at" class="mr-2" /> {{ item.name }}
                  <span v-if="item.nickname !== undefined">{{
                    item.name !== undefined
                      ? " (" + item.nickname + ")"
                      : item.nickname
                  }}</span
                  ><br />
                  <div class="mb-2" />
                  <CIcon name="cil-calendar" class="mr-2" />
                  {{ $t("login.joinDate") }}: {{ item.createdAt }}<br />
                  <div class="mb-2" />
                  <CIcon name="cil-share-alt" class="mr-2" />
                  {{ $t("login.snsType") }}: {{ item.snsType }}
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center">
          <div class="mt-3" style="width: 300px; max-width: 300px">
            <Button
              :disabled="
                selectedAccount === 0 || isBusy === true ? true : false
              "
              :label="$t('login.sendEmail')"
              :onClick="onSubmitAuthByEmail"
            />
            <span v-if="isBusy" class="mr-2">
              ({{ $t("login.sending") }}...)</span
            >
            <b-spinner small type="grow" v-if="isBusy"></b-spinner>
          </div>
        </div>
        <!-- <b-button
          squared
          variant="black"
          class="text-white mt-3 px-5"
          :disabled="selectedAccount === 0 || isBusy === true ? true : false"
          @click="onSubmitAuthByEmail()"
        >
          {{ $t("login.sendEmail") }}<span v-if="isBusy" class="mr-2"> ({{ $t("login.sending") }}...)</span>
          <b-spinner small type="grow" v-if="isBusy"></b-spinner>
        </b-button> -->

        <b-row class="justify-content-center pt-3">
          <a class="text-dark cursor-pointer" @click="hide()">
            <u>{{ $t("login.goBack") }}</u>
          </a>
        </b-row>
      </template>
      <template v-else>
        <p v-html="$t('login.resetMessage')"></p>
        <div class="d-flex justify-content-center mt-3 mb-4">
          <p
            class="text-black py-2"
            style="background-color: #f7f7f7; width: 80%; font-size: 12px"
          >
            * {{ $t("login.resetTimeoutMessage") }}
          </p>
        </div>

        <div class="d-flex justify-content-center">
          <div class="mt-3" style="width: 300px; max-width: 300px">
            <Button
              :disabled="
                selectedAccount === 0 || isBusy === true ? true : false
              "
              :label="$t('login.close')"
              :onClick="hide"
            />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { v4 as uuidv4 } from "uuid";
import { mapState, createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "FindAccount",
  components: {
    Button,
  },
  data() {
    return {
      form: {
        idx: null,
        account: "",
        language: localStorage.getItem("language") || "en",
      },
      isBusy: false,
      errorMessage: null,
      selectedAccount: 0,
      sendedEmail: false,
    };
  },
  computed: {
    ...authHelper.mapState(["accounts"]),
    ...mapState({
      errors: (state) => {
        return state.auth.errors || {};
      },
    }),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      account: { required },
    },
  },
  mounted() {},
  methods: {
    ...authHelper.mapActions(["getAccount", "sendEmailResetPassword"]),
    onLoginKakao() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/login/kakao/callback";
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;

      window.location.href = url;
    },
    onLoginNaver() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/login/naver/callback";
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;

      window.location.href = url;
    },
    onLoginGoogle() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/login/google/callback";
      const url =
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=713701466995-jef2uanlhjor7gbe5e15c20fodnar88u.apps.googleusercontent.com&redirect_uri=${redirect_uri}&response_type=code` +
        `&scope=openid%20email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.phonenumbers.read&access_type=offline`;

      window.location.href = url;
    },
    onSubmitFindAccount() {
      this.selectedAccount = 0; // 계정 선택상태 초기화

      this.$v.$touch();
      if (this.isValid) {
        this.getAccount(this.form)
          .then(() => {
            this.errorMessage = null;
            if (this.accounts !== undefined && this.accounts.length > 0) {
              this.$refs["resetPasswordModal"].show();
            } else {
              this.errorMessage = this.$t("login.noSearchData");
            }
          })
          .catch(() => {
            this.$log.error(this.errors);
            if (
              this.errors.error === "NOT_FOUND" &&
              this.errors.resource === "ENTITY"
            ) {
              this.errorMessage = this.$t("login.noSearchData");
            } else {
              this.errorMessage = this.$t("login.error.findAccount");
            }
          });
      }
    },
    onSelectAccount(i, idx) {
      this.selectedAccount = i;
      this.form.idx = idx;
    },
    onSubmitAuthByEmail() {
      if (this.form.idx !== undefined && this.form.idx > 0) {
        this.isBusy = true;
        this.sendEmailResetPassword(this.form)
          .then(() => {
            this.isBusy = false;
            this.sendedEmail = true;
          })
          .catch((error) => {
            this.isBusy = false;
            this.$log.error(error);
          });
      }
    },
    closeJoinSuccessModal() {
      this.$refs["resetPasswordModal"].hide();
    },
    hide() {
      this.$refs["resetPasswordModal"].hide();
      this.$router.push({ path: "/login" });
    },
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
  },
};
</script>

<style scoped>
.list-group-item.active {
  color: #000 !important;
  background-color: #fff !important;
}
</style>
